import { encodeQueryData } from './utils'

export default (httpClient) => ({
  jobs: {
    async retrieve(job) {
      const queryString = encodeQueryData({
        job
      })
      const response = await httpClient.get(`/filesystem/job/?${queryString}`)
      return response.data
    }
  }
})
