export const state = () => ({
  version: process.env.version,
  cart: JSON.parse(localStorage.getItem('cart')) || [],
  notifications: [],
  sideBarOpen: false,
  manualQCOpen: false,
  userCollections: undefined,
  startFrames: null,
  endFrames: null,
  rejection: undefined,
  categories: [],
  subCategories: [],
  userData: {},
  fps: undefined,
  assets: [],
  unmoderatedAssets: [],
  showFiltersBar: localStorage.getItem('showFiltersBar') === 'true',
  permissionsMap: {
    'Project Details': {
      DEVELOPERS: {
        edit: true,
        delete: true,
        add: true
      },
      PR: {
        edit: true,
        delete: true,
        add: true
      },
      PRODUCERS: {
        edit: true,
        delete: false,
        add: false
      }
    },
    'External Video': {
      DEVELOPERS: {
        edit: true,
        delete: true,
        add: true
      },
      PR: {
        edit: true,
        delete: true,
        add: true
      },
      PRODUCERS: {
        edit: false,
        delete: false,
        add: false
      }
    },
    Credits: {
      DEVELOPERS: {
        edit: true,
        delete: true,
        add: true
      },
      PR: {
        edit: true,
        delete: true,
        add: true
      },
      PRODUCERS: {
        edit: true,
        delete: false,
        add: false
      }
    },
    Tags: {
      DEVELOPERS: {
        edit: true,
        delete: true,
        add: true
      },
      PR: {
        edit: true,
        delete: true,
        add: true
      },
      PRODUCERS: {
        edit: true,
        delete: false,
        add: false
      }
    },
    'Non Standard Credits': {
      DEVELOPERS: {
        edit: true,
        delete: true,
        add: true
      },
      PR: {
        edit: true,
        delete: true,
        add: false
      },
      PRODUCERS: {
        edit: false,
        delete: false,
        add: false
      }
    },
    'Non Standard Tags': {
      DEVELOPERS: {
        edit: true,
        delete: true,
        add: true
      },
      PR: {
        edit: true,
        delete: true,
        add: false
      },
      PRODUCERS: {
        edit: false,
        delete: false,
        add: false
      }
    }
  }
})
export const getters = {
  userHasRole: (state) => (role) => {
    if (!state.userData.roles) {
      return false
    }
    return state.userData.roles.includes(role)
  },
  isPRUser: (state, getters) => {
    return getters.userHasRole('PR')
  },
  isProducerUser: (state, getters) => {
    return getters.userHasRole('PRODUCERS')
  },
  isReviewerUser: (state, getters) => {
    return getters.userHasRole('REVIEWERS')
  },
  isDeveloperUser: (state, getters) => {
    return getters.userHasRole('DEVELOPERS')
  },
  isTechopsUser: (state, getters) => {
    return getters.userHasRole('TECHOPS')
  },
  isMasterViewUser: (state, getters) => {
    return getters.userHasRole('MASTER_VIEW')
  },
  canDownloadRestricted: (state, getters) => {
    return getters.userHasRole('RESTRICTED_DOWNLOADERS')
  }
}
export const mutations = {
  TOGGLE_REJECTION: (state, assetId) => {
    state.rejection = assetId
  },
  SAVE_CATEGORIES: (state, categories) => {
    state.categories = categories
  },
  SAVE_SUBCATEGORIES: (state, subCategories) => {
    state.subCategories = subCategories
  },
  SAVE_FPS: (state, fps) => {
    state.fps = fps
  },
  SAVE_ASSETS: (state, assets) => {
    state.assets = assets
  },
  SAVE_SETTINGS: (state, settings) => {
    state.userData = settings
  },
  CLEAR_SETTINGS: (state) => {
    localStorage.removeItem('token')
    state.userData = {}
  },
  OPEN_SIDEBAR: (state) => {
    state.sideBarOpen = true
  },
  TOGGGLE_MANUALQC: (state) => {
    state.manualQCOpen = !state.manualQCOpen
  },
  TOGGLE_SIDEBAR: (state) => {
    state.sideBarOpen = !state.sideBarOpen
  },
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload
  },
  SET_COLLECTIONS: (state, payload) => {
    state.userCollections = payload
  },
  SET_START_FRAMES: (state, payload) => {
    state.startFrames = payload
  },
  SET_END_FRAMES: (state, payload) => {
    state.endFrames = payload
  },
  DELETE_NOTIFICATIONS: (state, index) => {
    state.notifications.splice(index, 1)
  },
  DELETE_COLLECTION: (state, index) => {
    state.userCollections.splice(index, 1)
  },
  ADD_ASSET_TO_CART: (state, asset) => {
    state.cart.push(asset)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  DELETE_ASSET_FROM_CART: (state, assetId) => {
    const pos = state.cart.findIndex(function(asset) {
      return asset.id === this
    }, assetId)
    if (pos > -1) {
      state.cart.splice(pos, 1)
    }
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },
  CLEAR_CART: (state) => {
    state.cart = []
    localStorage.removeItem('cart')
  },
  SHOW_FILTERS_BAR: (state, value) => {
    localStorage.setItem('showFiltersBar', value)
    state.showFiltersBar = value
  },
  UPDATE_USER_COLLECTIONS: (state, collection) => {
    state.userCollections.push(collection)
  },
  ADD_UNMODERATED_ASSETS: (state, assets) => {
    state.unmoderatedAssets = assets
  },
  REMOVE_UNMODERATED_ASSET: (state, assetId) => {
    const pos = state.unmoderatedAssets.findIndex(function(asset) {
      return asset.id === this
    }, assetId)
    if (pos > -1) {
      state.unmoderatedAssets.splice(pos, 1)
    }
  }
}
export const actions = {
  nuxtClientInit({ dispatch }) {
    dispatch('listUserCollections')
    dispatch('getUserSettings')
    dispatch('fetchCategories')
    dispatch('fetchSubCategories')
    dispatch('fetchFPS')
  },
  async getUserSettings({ commit, state }) {
    const response = await this.$shareAPI.settings.list()
    commit('SAVE_SETTINGS', response.results[0])
    if (this.$sentry) {
      this.$sentry.configureScope(function(scope) {
        scope.setUser({ username: state.userData.username })
      })
    }
  },

  addUnmoderatedAssets({ commit }, assets) {
    commit('ADD_UNMODERATED_ASSETS', assets)
  },
  removeUnmoderatedAsset({ commit }, asset) {
    commit('REMOVE_UNMODERATED_ASSET', asset)
  },

  toggleRejection({ commit }, assetId) {
    if (assetId) {
      commit('TOGGLE_REJECTION', assetId)
    } else {
      commit('TOGGLE_REJECTION')
    }
  },
  showFiltersBar({ commit }, value) {
    commit('SHOW_FILTERS_BAR', value)
  },
  toggleManualQC({ commit }, value) {
    commit('TOGGGLE_MANUALQC', value)
  },
  deleteCollection({ commit }, position) {
    commit('DELETE_COLLECTION', position)
  },
  clearCart({ commit }) {
    commit('CLEAR_CART')
  },
  setStartFrames({ commit }, value) {
    commit('SET_START_FRAMES', value)
  },
  setEndFrames({ commit }, value) {
    commit('SET_END_FRAMES', value)
  },
  clearSettings({ commit }) {
    commit('CLEAR_SETTINGS')
  },
  async fetchFPS({ commit }) {
    const response = await this.$shareAPI.metadata.list({
      name: 'fps',
      distinct: 'value'
    })
    const fps = []
    response.results.forEach((f) => fps.push(f.value))
    commit('SAVE_FPS', fps)
  },
  async fetchCategories({ commit }) {
    const response = await this.$shareAPI.categories.list()
    commit('SAVE_CATEGORIES', response.results)
  },
  async fetchSubCategories({ commit }) {
    const response = await this.$shareAPI.metadata.list({
      name: 'sequence',
      distinct: 'value'
    })
    const allCategories = []
    response.results.forEach((cat) => allCategories.push(cat.value))
    commit('SAVE_SUBCATEGORIES', allCategories)
  },
  async addAssetFromCollection(
    { commit, state, dispatch },
    { collectionID, assetID }
  ) {
    await this.$shareAPI.collections.addAsset(collectionID, assetID)
    // dispatch('listCollections')
  },
  async removeAssetFromCollection(
    { commit, state, dispatch },
    { collectionID, assetID }
  ) {
    await this.$shareAPI.collections.removeAsset(collectionID, assetID)
    // dispatch('listCollections')
  },
  listCollections({ commit }, filters) {
    return this.$shareAPI.collections.list(filters)
  },
  async listUserCollections({ commit }) {
    const response = await this.$shareAPI.collections.list({
      own: true
    })
    commit('SET_COLLECTIONS', response.results)
  },
  async createCollection({ dispatch, commit }, { name, privacy }) {
    const collection = await this.$shareAPI.collections.create(name, privacy)
    commit('UPDATE_USER_COLLECTIONS', collection)
    return collection
  },
  async createAssetDownload({ dispatch, commit }, payload) {
    try {
      await this.$shareAPI.downloads.create(payload)
      commit('OPEN_SIDEBAR', true)
    } catch (err) {
      return err.response.data
    }
  },
  setNotifications({ commit }, { data }) {
    commit('SET_NOTIFICATIONS', data)
  },
  getPermissionPerRole({ state }, { section, action }) {
    const roles = state.userData.roles

    const value = roles.some(
      (role) => state.permissionsMap[section][role][action]
    )
    return value
  },
  listMetadata({ state }, filters) {
    return this.$shareAPI.paginate(this.$shareAPI.metadata.list, filters)
  },
  listPreviews({ state }, filters) {
    return this.$shareAPI.paginate(this.$shareAPI.previews.list, filters)
  },
  listFavourites({ state }, filters) {
    return this.$shareAPI.paginate(this.$shareAPI.favourites.list, filters)
  },
  mergeAssetsData({ state }, { assetsData, previews, metadata, favourites }) {
    return assetsData.map((asset) => {
      const id = asset.id
      asset.category = state.categories.find(
        (category) => category.url === asset.category
      )
      if (asset.category.name !== 'still') {
        asset.video = previews.find(
          (preview) => preview.asset === id && preview.category === 'video'
        )
      }

      asset.thumbnail = previews.find(
        (preview) => preview.asset === id && preview.category === 'thumbnail'
      )
      asset.metadata = {}

      const metadataFromAsset = metadata.filter((m) => m.asset === id)
      for (const metadata of metadataFromAsset) {
        if (metadata.name in asset.metadata) {
          if (!Array.isArray(asset.metadata[metadata.name])) {
            asset.metadata[metadata.name] = [asset.metadata[metadata.name]]
          }
          asset.metadata[metadata.name].push(metadata.value)
        } else {
          asset.metadata[metadata.name] = metadata.value
        }
      }
      asset.favourite = favourites.find((f) => f.asset === id)
      return asset
    })
  },
  async fetchAsset({ dispatch, commit }, { assetId, isMetadata }) {
    const asset = await this.$shareAPI.assets.retrieve(assetId)
    const favourites = await dispatch('listFavourites', {
      asset: asset.id
    })
    const metadata = await dispatch('listMetadata', {
      asset: asset.id
    })
    const previews = await dispatch('listPreviews', {
      asset: asset.id
    })
    const transformedAssets = await dispatch('mergeAssetsData', {
      assetsData: [asset],
      previews,
      metadata,
      favourites
    })
    if (isMetadata) {
      transformedAssets[0].metadata = metadata
    }
    return transformedAssets[0]
  },
  async fetchAssets(
    { state, dispatch, getters, commit },
    { filters, paginateAll }
  ) {
    if (getters.isPRUser || getters.isProducerUser) {
      filters.category = ['PR Approved Asset']
    } else if (getters.isTechopsUser) {
      filters.category = ['Unchecked Master']
    } else {
      filters.exclude_type = ['Unchecked Master']
    }

    let assetsData
    let totalCount
    if (paginateAll) {
      assetsData = await this.$shareAPI.paginate(
        this.$shareAPI.assets.list,
        filters
      )
      totalCount = assetsData.length
    } else {
      const response = await this.$shareAPI.assets.list(filters)
      assetsData = response.results
      totalCount = response.count
    }
    if (assetsData.length < 1) {
      return { assets: [], totalCount }
    }
    const assetIds = assetsData.map((asset) => asset.id)
    const name = [
      'resolution',
      'asset_name',
      'fps',
      'Project Details # Brand',
      'Project Details # Project Title'
    ]

    const metadata = await dispatch('listMetadata', {
      asset: assetIds,
      name
    })

    const previews = await dispatch('listPreviews', { asset: assetIds })
    const favourites = await dispatch('listFavourites', { asset: assetIds })
    const assets = await dispatch('mergeAssetsData', {
      assetsData,
      metadata,
      previews,
      favourites
    })
    commit('SAVE_ASSETS', assets)

    return { assets, totalCount }
  }
}
