<template>
  <v-app>
    <v-layout align-center justify-center>
      <NotFound v-if="error.statusCode === 404" />
      <InternalError v-else-if="error.statusCode === 500" />
    </v-layout>
  </v-app>
</template>

<script>
import NotFound from '~/components/NotFound'
import InternalError from '~/components/InternalError'

export default {
  layout: 'no-toolbar',
  components: {
    NotFound,
    InternalError
  },
  props: {
    error: {
      type: Object,
      required: true
    }
  }
}
</script>
