<template>
  <v-list-item :key="notification.title" class="cart-item">
    <v-list-item-avatar>
      <v-progress-circular
        :rotate="-90"
        :size="40"
        :width="2"
        :indeterminate="getIndeterminate()"
        :value="calculatePercentage()"
        :color="getProgressColor()"
      >
        <v-icon :color="getProgressColor()" small>{{ getIcon() }}</v-icon>
      </v-progress-circular>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <router-link
          class="download-item"
          :to="{
            name: 'assets-id',
            params: { id: notification.asset }
          }"
          >Asset {{ notification.asset_name }}</router-link
        >
      </v-list-item-title>

      <v-list-item-subtitle class="text--white">
        From {{ notification.from_file }} to
        {{ notification.to_file }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--white">
        {{ notification.project }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--white">
        {{ notification.site }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action v-if="!notification.failed">
      <v-list-item-action-text v-if="notification.phase === '1'"
        >Download in queue</v-list-item-action-text
      >

      <v-list-item-action-text v-else-if="notification.phase === '2'">
        {{ notification.network_speed | toMegaBytes }}
        MB/s
      </v-list-item-action-text>
      <v-list-item-action-text v-else-if="notification.phase === '3'"
        >Download completed</v-list-item-action-text
      >
      <v-list-item-action-text v-else-if="notification.phase === 'MPC-1'"
        >Creating asset in Ftrack</v-list-item-action-text
      >
      <v-list-item-action-text v-else-if="notification.phase === 'MPC-2'"
        >Moving files to final folder</v-list-item-action-text
      >
      <v-icon
        v-if="notification.failed && !retried"
        title="Retry download"
        color="red"
        @click="retry"
        >mdi-loop</v-icon
      >
      <v-icon v-else></v-icon>
    </v-list-item-action>
    <v-alert v-else type="info">
      Download failed for {{ notification.project }}. Please create a support
      ticket in the HelpDesk</v-alert
    >
  </v-list-item>
</template>

<script>
export default {
  filters: {
    toMegaBytes(bytes) {
      const mb = bytes / (1024 * 1024)
      return mb.toFixed(2)
    }
  },
  props: {
    notification: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      retried: false
    }
  },
  methods: {
    calculatePercentage() {
      if (this.notification.failed) {
        return 100
      }
      if (this.notification.phase === '2') {
        const amountOfFiles =
          this.notification.to_file + 1 - this.notification.from_file
        return (this.notification.files_downloaded / amountOfFiles) * 100
      }
      if (this.notification.phase === '3') {
        return 100
      }
    },
    async retry() {
      this.retried = true
      await this.$shareAPI.downloads.retry(this.notification.id)
    },
    getIcon() {
      if (this.notification.failed && this.retried) {
        return 'mdi-loop'
      } else if (this.notification.failed) {
        return 'mdi-alert-circle'
      } else if (this.notification.phase === '2') {
        return 'mdi-download'
      } else if (this.notification.phase === '3') {
        return 'mdi-check'
      } else if (this.notification.phase === 'MPC-1') {
        return 'mdi-alpha-f-box'
      } else if (this.notification.phase === 'MPC-2') {
        return 'mdi-transfer-right'
      } else if (this.notification.files_downloaded === 0) {
        return 'mdi-timer-sand'
      }
      return 'mdi-download'
    },
    getProgressColor() {
      if (this.notification.failed) {
        return 'red'
      } else if (this.notification.phase === '3') {
        return 'green'
      } else if (this.notification.phase === 'MPC-1') {
        return 'purple'
      } else if (this.notification.phase === 'MPC-2') {
        return 'purple'
      }
      return 'blue'
    },
    getIndeterminate() {
      if (this.notification.phase === '3') {
        return false
      }
    }
  }
}
</script>

<style>
a.download-item.router-link-active {
  text-decoration: none !important;
}
</style>
