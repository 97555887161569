import { render, staticRenderFns } from "./DownloadNotificationList.vue?vue&type=template&id=23b99190&scoped=true&"
import script from "./DownloadNotificationList.vue?vue&type=script&lang=js&"
export * from "./DownloadNotificationList.vue?vue&type=script&lang=js&"
import style0 from "./DownloadNotificationList.vue?vue&type=style&index=0&id=23b99190&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b99190",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VContainer,VDivider,VFlex,VIcon,VLayout,VList,VListItemGroup})
