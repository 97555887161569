import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4e64555e = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _640d39e1 = () => interopDefault(import('../pages/delivery/index.vue' /* webpackChunkName: "pages/delivery/index" */))
const _435598f6 = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _20124a1e = () => interopDefault(import('../pages/downloads.vue' /* webpackChunkName: "pages/downloads" */))
const _213ff354 = () => interopDefault(import('../pages/favourites.vue' /* webpackChunkName: "pages/favourites" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5172b96e = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _fcac5c9a = () => interopDefault(import('../pages/okta.vue' /* webpackChunkName: "pages/okta" */))
const _a64b306e = () => interopDefault(import('../pages/resMap.js' /* webpackChunkName: "pages/resMap" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _f5e1a5e4 = () => interopDefault(import('../pages/upload-asset.vue' /* webpackChunkName: "pages/upload-asset" */))
const _6a1506d0 = () => interopDefault(import('../pages/uploads.vue' /* webpackChunkName: "pages/uploads" */))
const _8e1272a8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9a19d152 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _9d9eae54 = () => interopDefault(import('../pages/auth/okta.vue' /* webpackChunkName: "pages/auth/okta" */))
const _6cb7f0fe = () => interopDefault(import('../pages/user/collections/index.vue' /* webpackChunkName: "pages/user/collections/index" */))
const _0831da0e = () => interopDefault(import('../pages/delivery/masters/_id/index.vue' /* webpackChunkName: "pages/delivery/masters/_id/index" */))
const _5a835af8 = () => interopDefault(import('../pages/delivery/wips/_id/index.vue' /* webpackChunkName: "pages/delivery/wips/_id/index" */))
const _17d7462e = () => interopDefault(import('../pages/user/collections/_id/index.vue' /* webpackChunkName: "pages/user/collections/_id/index" */))
const _e88d0bca = () => interopDefault(import('../pages/assets/_id/index.vue' /* webpackChunkName: "pages/assets/_id/index" */))
const _b390dd6e = () => interopDefault(import('../pages/collections/_id/index.vue' /* webpackChunkName: "pages/collections/_id/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/collections",
    component: _4e64555e,
    name: "collections"
  }, {
    path: "/delivery",
    component: _640d39e1,
    name: "delivery"
  }, {
    path: "/disclaimer",
    component: _435598f6,
    name: "disclaimer"
  }, {
    path: "/downloads",
    component: _20124a1e,
    name: "downloads"
  }, {
    path: "/favourites",
    component: _213ff354,
    name: "favourites"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout"
  }, {
    path: "/notifications",
    component: _5172b96e,
    name: "notifications"
  }, {
    path: "/okta",
    component: _fcac5c9a,
    name: "okta"
  }, {
    path: "/resMap",
    component: _a64b306e,
    name: "resMap"
  }, {
    path: "/search",
    component: _3d268e12,
    name: "search"
  }, {
    path: "/upload-asset",
    component: _f5e1a5e4,
    name: "upload-asset"
  }, {
    path: "/uploads",
    component: _6a1506d0,
    name: "uploads"
  }, {
    path: "/auth/login",
    component: _8e1272a8,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _9a19d152,
    name: "auth-logout"
  }, {
    path: "/auth/okta",
    component: _9d9eae54,
    name: "auth-okta"
  }, {
    path: "/user/collections",
    component: _6cb7f0fe,
    name: "user-collections"
  }, {
    path: "/delivery/masters/:id",
    component: _0831da0e,
    name: "delivery-masters-id"
  }, {
    path: "/delivery/wips/:id",
    component: _5a835af8,
    name: "delivery-wips-id"
  }, {
    path: "/user/collections/:id",
    component: _17d7462e,
    name: "user-collections-id"
  }, {
    path: "/assets/:id",
    component: _e88d0bca,
    name: "assets-id"
  }, {
    path: "/collections/:id",
    component: _b390dd6e,
    name: "collections-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
