<template>
  <v-app>
    <AppSideBar />
    <AppBar />

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <nuxt />
      </v-container>
    </v-main>
    <AppFooter />
  </v-app>
</template>

<script>
import AppSideBar from '~/components/AppSideBar'
import AppBar from '~/components/AppBar'
import AppFooter from '~/components/AppFooter'

export default {
  components: {
    AppSideBar,
    AppBar,
    AppFooter
  },
  async middleware({ store, redirect }) {
    await store.dispatch('nuxtClientInit')
  }
}
</script>
