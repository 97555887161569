<template>
  <v-list-item :key="asset.title" class="cart-item">
    <v-list-item-avatar>
      <v-progress-circular :size="40" :width="2" :value="100" color="blue">
        <v-icon small>{{ asset.category.icon }}</v-icon>
      </v-progress-circular>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <router-link
          class="download-item"
          :to="{
            name: 'assets-id',
            params: { id: asset.id }
          }"
          >{{ assetName }}</router-link
        >
      </v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-icon color="red" @click="DELETE_ASSET_FROM_CART(asset.id)"
        >mdi-close-circle</v-icon
      >
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    asset: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      assetName: undefined
    }
  },
  created() {
    if (this.asset.metadata.asset_name) {
      this.assetName = this.asset.metadata.asset_name
    } else {
      this.assetName = this.asset.metadata.find(
        (a) => a.name === 'asset_name'
      ).value
    }
  },
  methods: {
    ...mapMutations(['DELETE_ASSET_FROM_CART'])
  }
}
</script>

<style>
.cart-item a.download-item {
  text-decoration: none !important;
}
</style>
