<template>
  <v-list two-line>
    <v-list-item-group active-class="white--text">
      <template v-for="(asset, index) in cart">
        <CartItem :key="asset.id" :position="index" :asset="asset" />

        <v-divider v-if="index + 1 < cart.length" :key="index"></v-divider>
      </template>
    </v-list-item-group>
    <div v-if="cart.length > 0">
      <v-btn
        block
        text
        color="primary"
        text-xs-right
        @click="downloadOptionsMenu = !downloadOptionsMenu"
        >Download all assets</v-btn
      >

      <DownloadOptionsMPC
        v-if="downloadOptionsMenu"
        :assets="cart"
        :multiple="true"
      />
    </div>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import CartItem from '~/components/CartItem'
import DownloadOptionsMPC from '~/components/DownloadOptionsMPC'

export default {
  components: {
    CartItem,
    DownloadOptionsMPC
  },
  data() {
    return {
      downloadOptionsMenu: false
    }
  },
  computed: mapState(['cart'])
}
</script>
