<template>
  <v-footer dark inset padless>
    <v-layout justify-center wrap>
      <v-flex text-center xs12>
        <strong>PIPEVFX: Share</strong>
        &copy; {{ new Date().getFullYear() }} — v{{ version }}
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      version: process.env.version
    }
  }
}
</script>
