/**
 * Convert the provided dictionary into a URI compatible query string
 * @param {Object} dictionary of key values
 * @returns {string} query string
 */
export function encodeQueryData(data) {
  const ret = []
  for (const d in data) {
    if (!data[d]) {
      continue
    }
    if (typeof data[d] === 'object') {
      for (const val of data[d]) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(val))
      }
    } else {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }
  }
  return ret.join('&')
}
