<template>
  <v-layout row>
    <v-flex v-if="$route.path.startsWith('/search')" md2 ma-2>
      <v-btn color="secondary" block @click="toggleFiltersBar">
        <span v-if="showFiltersBar">Hide filters bar</span>
        <span v-else>Show filters bar</span>
      </v-btn>
    </v-flex>
    <v-flex>
      <v-text-field
        v-model="searchInput"
        hide-details
        solo
        placeholder="Search"
        @keyup.enter="search"
      >
        <template v-slot:append>
          <v-btn color="secondary" @click="search">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      unicorn: window.cornify_add,
      searchInput: ''
    }
  },
  computed: mapState(['showFiltersBar']),
  watch: {
    $route: 'updateSearchInput'
  },
  created() {
    this.updateSearchInput()
  },
  methods: {
    updateSearchInput() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q.replace(/-/g, ' ')
      } else if (this.$route.path === '/search') {
        this.searchInput = ''
      }
    },
    search() {
      if (['unicorn', 'rainbow'].includes(this.searchInput)) {
        this.unicorn()
      }
      const keywords = this.searchInput.replace(/\s/g, '-')
      const query = { ...this.$route.query }
      query.q = keywords
      query.page = undefined
      this.$router.push({
        path: '/search',
        query
      })
    },
    toggleFiltersBar() {
      this.$store.dispatch('showFiltersBar', !this.showFiltersBar)
    }
  }
}
</script>
