import { encodeQueryData } from './utils'

export default (httpClient) => ({
  projects: {
    async exists(search) {
      const queryString = encodeQueryData({
        name: search
      })
      const response = await httpClient.get(`/projects/?${queryString}`)
      return response.data
    }
  }
})
