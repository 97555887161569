<template>
  <v-card color="#212121" dark>
    <v-card-text>
      <v-layout row wrap>
        <v-flex md12>
          <v-alert v-if="error" dismissible type="error">{{ error }}</v-alert>
        </v-flex>

        <v-flex
          v-if="
            !assets[0].is_single_file &&
              !multiple &&
              assets[0].category.name !== 'still'
          "
          md12
        >
          <v-layout>
            <v-flex md6>
              <v-text-field
                label="From frame"
                disabled
                :value="startFrames"
              ></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field
                label="To frame"
                disabled
                :value="endFrames"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md12 pa-2>
          <AppAutoCompleteCetaProjects @projectChanged="projectChanged" />
        </v-flex>
        <v-flex md12 pa-2>
          <v-select
            v-if="!selectedProject"
            disabled
            label="Select a project to display available sites"
          ></v-select>
          <v-select
            v-else
            v-model="selectedSite"
            :items="availableSites"
            :label="`Available sites for ${selectedProject}`"
            item-value="name"
            item-text="name"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        :disabled="!selectedProject || !selectedSite"
        color="success"
        @click="downloadAsset"
        >Confirm Download</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import AppAutoCompleteCetaProjects from './AppAutoCompleteCetaProjects'

export default {
  components: {
    AppAutoCompleteCetaProjects
  },
  props: {
    assets: {
      type: Array,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedSite: undefined,
      selectedProject: undefined,
      sites: [],
      availableSites: [],
      error: null
    }
  },
  computed: mapState(['startFrames', 'endFrames']),
  async created() {
    const response = await this.$shareAPI.sites.list()
    this.sites = response.results
  },
  methods: {
    projectChanged(project) {
      if (project) {
        this.selectedProject = project.name
        this.updateAvailableSites(project.sites)
      } else {
        this.selectedProject = undefined
      }
    },
    updateAvailableSites(projectSites) {
      if (!projectSites) {
        this.availableSites = []
        this.selectedSite = undefined
        return
      }
      const sitesMap = projectSites.map((ps) => {
        const site = this.sites.find((site) => {
          return site.code.includes(ps)
        })
        if (!site) {
          return undefined
        }
        return {
          name: site.name,
          code: site.code
        }
      })
      this.availableSites = sitesMap.filter(Boolean)
    },
    async downloadAsset() {
      this.error = null
      const site = this.sites.find((s) => s.name === this.selectedSite)
      let fromFile
      let toFile

      if (!this.multiple && !this.assets[0].is_single_file) {
        const metadataFirstFileValue = parseInt(
          this.assets[0].metadata.filter(
            (item) => item.name === 'first_file'
          )[0].value
        )
        if (this.assets[0].category.name === 'still') {
          fromFile = 1001
          toFile = 1001
        } else if (metadataFirstFileValue > 1000) {
          fromFile = this.startFrames === 0 ? 1001 : this.startFrames + 1000
          toFile = this.endFrames + 1000
        } else {
          fromFile = this.startFrames === 0 ? 1 : this.startFrames
          toFile = this.endFrames
        }
      }

      for (const asset of this.assets) {
        const errorMessage = await this.$store.dispatch('createAssetDownload', {
          asset: asset.id,
          site: site.id,
          project: this.selectedProject,
          from_file: fromFile,
          to_file: toFile
        })
        if (errorMessage) {
          const keys = Object.keys(errorMessage)
          console.log(errorMessage)
          this.error = errorMessage[keys][0]
        }
      }
      this.$store.dispatch('clearCart')
    }
  }
}
</script>
