<template>
  <Nuxt />
</template>

<script>
export default {
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css'
        }
      ]
    }
  }
}
</script>
