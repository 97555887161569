<template>
  <div>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <v-autocomplete
      v-model="selectedProject"
      :items="projects"
      item-text="name"
      :return-object="true"
      :loading="loading"
      label="To project"
      placeholder="Type at least 4 characters to search for a project"
      @input.native="getProjects"
      @change="projectChanged"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedProject: undefined,
      loading: false,
      projects: [],
      error: undefined
    }
  },
  methods: {
    async getProjects(event) {
      const projectName = event.target.value
      if (projectName.length != null && projectName.length > 3) {
        this.loading = true
        try {
          const projects = await this.$shareAPI.projects.list({
            search: projectName
          })
          this.projects = projects
        } catch (error) {
          this.error = error
        } finally {
          this.loading = false
        }
      } else {
        this.projects = []
        this.error = undefined
        this.selectedProject = undefined
        this.$emit('projectChanged', undefined)
      }
    },
    projectChanged() {
      this.$emit('projectChanged', this.selectedProject)
    }
  }
}
</script>
