<template>
  <v-list two-line>
    <v-list-item-group active-class="white--text">
      <template
        v-for="(notification, index) in notifications.filter(
          (notif) => notif.phase === '3'
        )"
      >
        <CompletedDownloadItem
          :key="notification.id"
          :position="index"
          :notification="notification"
        />
        <v-divider
          v-if="index + 1 < notifications.length"
          :key="`divider-${index}`"
        ></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import CompletedDownloadItem from '~/components/CompletedDownloadItem'

export default {
  components: {
    CompletedDownloadItem
  },
  data() {
    return {
      interval: undefined,
      userDownloads: []
    }
  },
  computed: mapState(['notifications']),
  created() {
    this.interval = setInterval(this.fetchNotifications, 5000)
  },
  methods: {}
}
</script>

<style lang="sass" scoped>
.download-legend
  color: gray
</style>
