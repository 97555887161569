<template>
  <v-app>
    <DeliveryBar />

    <!-- Sizes your content based upon application components -->
    <v-content>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <nuxt />
      </v-container>
    </v-content>
    <AppFooter />
  </v-app>
</template>

<script>
import DeliveryBar from '~/components/DeliveryBar'
import AppFooter from '~/components/AppFooter'

export default {
  components: {
    DeliveryBar,
    AppFooter
  }
}
</script>
