<template>
  <v-container>
    <v-layout style="padding-left: 70px" dark row wrap class="download-legend">
      <v-flex>
        <v-flex>
          <small>
            <v-icon color="blue" small>mdi-timer-sand</v-icon>Download in the
            queue
          </small>
        </v-flex>
        <v-flex>
          <small>
            <v-icon color="blue" small>mdi-download</v-icon>Download in progress
          </small>
        </v-flex>
      </v-flex>
      <v-flex xs6>
        <v-flex>
          <small>
            <v-icon color="purple" small>mdi-alpha-f-box</v-icon>Creating asset
            in Ftrack
          </small>
        </v-flex>
        <v-flex>
          <small>
            <v-icon color="red" small>mdi-alert-circle</v-icon>Download failed
          </small>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-list two-line>
      <v-list-item-group active-class="white--text">
        <template
          v-for="(notification, index) in notifications.filter(
            (notif) => notif.phase !== '3'
          )"
        >
          <DownloadItem
            :key="notification.id"
            :position="index"
            :notification="notification"
          />

          <v-divider
            v-if="index + 1 < notifications.length"
            :key="`divider-${index}`"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import DownloadItem from '~/components/DownloadItem'

export default {
  components: {
    DownloadItem,
  },
  data() {
    return {
      interval: undefined,
    }
  },
  computed: mapState(['notifications']),
  created() {
    const token = this.$auth.getToken('local')
    if (!token) {
      return
    }
    let url = ''
    if (process.env.shareAPI.startsWith('/')) {
      url = location.origin
      url += process.env.shareAPI
    } else {
      url = process.env.shareAPI + '/'
    }
    url = url.replace(/https?/g, 'wss')
    this.$connect(`${url}downloads/?token=${token}`, {
      format: 'json',
    })
    this.$options.sockets.onmessage = (event) => {
      const downloads = JSON.parse(event.data).map((d) => d.fields)
      this.$store.dispatch('setNotifications', { data: downloads })
    }
    this.interval = setInterval(() => this.$socket.send(''), 5000)
  },
  destroyed() {
    clearInterval(this.interval)
    this.$disconnect()
  },
}
</script>

<style lang="sass" scoped>
.download-legend
  color: gray
</style>
