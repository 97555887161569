<template>
  <v-app-bar app>
    <v-toolbar-title>
      <router-link to="/">
        <img src="/logo.png" height="32px" />
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <nuxt-link class="custom-btn" to="upload-asset">
      Upload new asset
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
      </svg>
    </nuxt-link> -->
    <div class="mr-3">
      <v-dialog v-model="dialog" scrollable max-width="500px">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge color="red" top :value="userNotifications.length > 0">
              <span slot="badge">{{ userNotifications.length }}</span>
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card v-if="userNotifications.length > 0">
          <v-card-title>All notifications</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px;">
            <router-link to="/notifications"
              ><li
                v-for="(notification, index) in userNotifications"
                :key="notification.id"
                @click="showAllandRead(notification.id, index)"
              >
                {{ notification.data.asset_name }} was
                {{ notification.description }} by
                {{ notification.data.moderator }}
              </li>
            </router-link>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="markAllAsRead()"
              >Mark all as read</v-btn
            >
            <router-link to="/notifications">
              <v-btn color="blue darken-1" text @click="dialog = false"
                >View all</v-btn
              >
            </router-link>
          </v-card-actions>
        </v-card>

        <v-card v-else>
          <v-card-text> You have 0 new notifications </v-card-text>
          <router-link to="/notifications">
            <v-btn color="blue darken-1" text @click="dialog = false"
              >View all</v-btn
            >
          </router-link>
        </v-card>
      </v-dialog>
    </div>
    <div class="mr-3">
      <v-btn v-if="isReviewerUser" to="/uploads/" text>
        <v-badge color="red" top :value="unmoderatedAssets.length > 0">
          <span slot="badge">{{ unmoderatedAssets.length }}</span>
          <v-icon>mdi-gavel</v-icon>
        </v-badge>
      </v-btn>
    </div>

    <v-btn v-if="isTechopsUser || isMasterViewUser" to="/delivery/" icon>
      <v-icon>mdi-video-plus</v-icon>
    </v-btn>
    <v-menu left bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-image-multiple</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item to="/user/collections/">
          <v-list-item-title>Your Collections</v-list-item-title>
        </v-list-item>
        <v-list-item to="/collections/">
          <v-list-item-title>Public Collections</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <template v-if="$vuetify.breakpoint.smAndUp">
      <v-menu left bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <!-- <v-list-item to="/fileUploads/">
            <v-list-item-title>Upload file </v-list-item-title>
          </v-list-item> -->
          <v-list-item to="/notifications/">
            <v-list-item-title>All notifications</v-list-item-title>
          </v-list-item>
          <v-list-item to="/logout/">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="mr-3">
        <v-btn icon @click="TOGGLE_SIDEBAR">
          <v-badge color="red" top :value="cart.length > 0">
            <span slot="badge">{{ cart.length }}</span>
            <v-icon>mdi-folder-download</v-icon>
          </v-badge>
        </v-btn>
      </div>
    </template>
    <AppSearchBar slot="extension" />
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import AppSearchBar from '~/components/AppSearchBar'

export default {
  components: {
    AppSearchBar
  },
  data() {
    return {
      userNotifications: [],
      dialog: false
    }
  },
  computed: {
    ...mapState(['cart', 'unmoderatedAssets']),
    ...mapGetters(['isReviewerUser', 'isTechopsUser', 'isMasterViewUser'])
  },
  async created() {
    const userSite = this.$store.state.userData.site
    const { assets } = await this.$store.dispatch('fetchAssets', {
      filters: {
        page: this.currentPage,
        status: 'Pending',
        site: userSite
      }
    })
    this.$store.dispatch('addUnmoderatedAssets', assets)
  },

  methods: {
    ...mapMutations(['TOGGLE_SIDEBAR']),
    async showAllandRead(id, index) {
      this.dialog = false
      await this.$shareAPI.notifications.markAsRead(id)
      this.userNotifications.splice(index, 1)
      console.log(this.userNotifications)
    },
    markAllAsRead() {
      this.userNotifications.forEach(async (element, index) => {
        await this.$shareAPI.notifications.markAsRead(element.id)
        this.userNotifications = []
      })
    }
  }
}
</script>

<style>
.custom-btn {
  display: flex;
  background-color: #ff8f00;
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.25rem;
  font-weight: 700;
  text-decoration: none;
}
a.custom-btn {
  color: white !important;
}
.custom-btn svg {
  margin-left: 0.5rem;
  fill: white;
}
.custom-btn:hover {
  background-color: #ad6302;
}

.v-toolbar__extension {
  margin: 10px 0;
}
</style>
