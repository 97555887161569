<template>
  <v-navigation-drawer
    app
    color="#212121"
    :permanent="sideBarOpen"
    :value="sideBarOpen"
    :disable-resize-watcher="true"
    :hide-overlay="true"
    width="500"
    right
  >
    <v-toolbar color="#212121">
      <v-tabs v-model="currentTab" background-color="#212121">
        <v-tab v-for="item in tabs" :key="item" :href="`#tab-${item}`">
          {{ item }}
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="currentTab">
      <v-tab-item
        v-for="item in tabs"
        :key="item"
        :value="'tab-' + item"
        transition="false"
        reverse-transition="false"
      >
        <div v-if="currentTab === 'tab-Pending Downloads'">
          <CartList />
          <DownloadNotificationList />
        </div>
        <CompletedDownloadsList v-else />
      </v-tab-item>
    </v-tabs-items>
    <v-divider light inset />
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import DownloadNotificationList from '~/components/DownloadNotificationList'
import CartList from '~/components/CartList'
import CompletedDownloadsList from '~/components/CompletedDownloadsList'

export default {
  components: {
    DownloadNotificationList,
    CartList,
    CompletedDownloadsList
  },
  data() {
    return {
      currentTab: 'tab-Pending Downloads',
      tabs: ['Pending Downloads', 'Completed Downloads']
    }
  },
  computed: mapState(['sideBarOpen']),
  methods: {}
}
</script>
