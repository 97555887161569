<template>
  <v-list-item :key="notification.title" class="cart-item">
    <v-list-item-avatar>
      <v-progress-circular
        rotate="-90"
        size="40"
        width="2"
        color="green"
        value="100"
      >
        <v-icon color="green" small>mdi-check</v-icon>
      </v-progress-circular>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <router-link
          class="download-item"
          :to="{
            name: 'assets-id',
            params: { id: notification.asset }
          }"
          >Asset {{ notification.asset_name }}</router-link
        >
      </v-list-item-title>
      <v-list-item-subtitle class="text--white">
        From {{ notification.from_file }} to
        {{ notification.to_file }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--white">
        {{ notification.project }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="text--white">
        {{ notification.site }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text v-if="notification.phase === '3'">{{
        new Date(notification.created_at).toLocaleString()
      }}</v-list-item-action-text>
      <v-icon></v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      retried: false
    }
  }
}
</script>

<style>
a.download-item.router-link-active {
  text-decoration: none !important;
}
</style>
