import { encodeQueryData } from './utils'

export default (httpClient) => ({
  async paginate(func, qs) {
    const response = await func(qs)
    let next = response.next
    let results = response.results
    while (next) {
      let response = await httpClient.get(next)
      response = response.data
      next = response.next
      results = results.concat(response.results)
    }
    return results
  },
  categories: {
    async list() {
      const response = await httpClient.get(`/categories/`)
      return response.data
    }
  },
  fps: {
    async list() {
      const response = await httpClient.get('/fps/')
      return response.data
    }
  },
  assets: {
    async create(payload) {
      const response = await httpClient.post(`/assets/`, payload)
      return response.data
    },
    async list(qs) {
      const queryString = encodeQueryData(qs)
      const response = await httpClient.get(`/assets/?${queryString}`)
      return response.data
    },
    async retrieve(id) {
      const response = await httpClient.get(`/assets/${id}/`)
      return response.data
    },
    async update(assetId, payload) {
      const response = await httpClient.patch(`/assets/${assetId}/`, payload)
      return response.data
    }
  },
  collections: {
    async list(payload) {
      const queryString = encodeQueryData(payload)
      const response = await httpClient.get(`/collections/?${queryString}`)
      return response.data
    },

    async retrieve(id) {
      const response = await httpClient.get(`/collections/${id}/`)
      return response.data
    },

    async create(name, privacy) {
      const response = await httpClient.post('/collections/', {
        name,
        privacy
      })
      return response.data
    },
    async update(collectionId, payload) {
      const response = await httpClient.patch(
        `/collections/${collectionId}/`,
        payload
      )
      return response.data
    },
    async delete(id) {
      const response = await httpClient.delete(`/collections/${id}/`)
      return response.status
    },
    async addAsset(id, assetID) {
      const payload = {
        asset: assetID
      }
      const response = await httpClient.post(
        `/collections/${id}/assets/`,
        payload
      )
      return response.data
    },
    async removeAsset(id, assetID) {
      const response = await httpClient.delete(
        `/collections/${id}/assets/${assetID}/`
      )
      return response.status
    },
    async getAssets(id) {
      const response = await httpClient.get(`/collections/${id}/assets/`)
      return response.data
    }
  },
  favourites: {
    async list(qs) {
      const queryString = encodeQueryData(qs)
      const response = await httpClient.get(`/favourites/?${queryString}`)
      return response.data
    },

    async create(assetId) {
      const response = await httpClient.post('/favourites/', {
        asset: assetId
      })
      return response.data
    },

    async delete(id) {
      const response = await httpClient.delete(`/favourites/${id}/`)
      return response.status
    }
  },
  downloads: {
    async create(payload) {
      const response = await httpClient.post('/downloads/', payload)
      return response.data
    },
    async retry(id) {
      const response = await httpClient.put(`/downloads/${id}/`)
      return response.status
    }
  },
  metadata: {
    async list(qs) {
      const queryString = encodeQueryData(qs)
      const response = await httpClient.get(`/metadata/?${queryString}`)
      return response.data
    },
    async create(payload) {
      const response = await httpClient.post(`/metadata/`, payload)
      return response.data
    },
    async delete({ id }) {
      const response = await httpClient.delete(`/metadata/${id}/`)
      return response.status
    },
    async update(id, payload) {
      const response = await httpClient.patch(`/metadata/${id}/`, payload)
      return response.status
    }
  },
  ratings: {
    async list({ asset }) {
      const response = await httpClient.get(`assets/${asset}/ratings/`)
      return response.data
    },
    async create(asset, value) {
      const response = await httpClient.post(`assets/${asset}/ratings/`, {
        value
      })
      return response.data
    },
    async update(asset, value) {
      const response = await httpClient.patch(`assets/${asset}/ratings/`, {
        value
      })
      return response.data
    }
  },
  preuploads: {
    async create(payload) {
      const response = await httpClient.post('/preuploads/', payload)
      return response.data
    }
  },
  uploads: {
    async list({ moderationStatus }) {
      const queryString = moderationStatus.map((ms) => `status=${ms}`).join('&')
      const response = await httpClient.get(`/uploads/?${queryString}`)
      return response.data
    },
    async create(assetID) {
      const payload = { asset_id: assetID }
      const response = await httpClient.post('/uploads/', payload)
      return response.data
    },
    async createLegacy(category, path, user, metadata) {
      const payload = { category, path, user, metadata }
      const response = await httpClient.post('/uploads/', payload)
      return response.data
    },
    async update(assetId, payload) {
      const response = await httpClient.patch(`/uploads/${assetId}/`, payload)
      return response.data
    }
  },
  downloadsHistory: {
    async list() {
      const response = await httpClient.get(`/downloads-history/`)
      return response.data
    }
  },
  sites: {
    async list() {
      const response = await httpClient.get(`/sites/`)
      return response.data
    }
  },
  relatedKeywords: {
    async list({ keywords }) {
      const response = await httpClient.get(`/keywords/?keyword=${keywords}`)
      return response.data
    }
  },
  deliveryAccess: {
    async list() {
      const response = await httpClient.get(`/delivery-access`)
      return response.status
    }
  },
  previews: {
    async list(qs) {
      const queryString = encodeQueryData(qs)
      const response = await httpClient.get(`/previews/?${queryString}`)
      return response.data
    }
  },
  settings: {
    async list(qs) {
      const response = await httpClient.get(`/settings/`)
      return response.data
    }
  },
  orgSettings: {
    async list(qs) {
      const response = await httpClient.get(`/orgsettings/`)
      return response.data
    }
  },
  notifications: {
    async list(qs) {
      const queryString = encodeQueryData(qs)
      const response = await httpClient.get(`/notifications/?${queryString}`)
      return response.data
    },
    async listUnread() {
      const response = await httpClient.get(`/notifications/?unread=true`)
      return response.data
    },
    async markAsRead(id) {
      const response = await httpClient.delete(`/notifications/${id}/`)
      return response.data
    }
  },
  projects: {
    async list(qs) {
      const queryString = encodeQueryData(qs)
      const response = await httpClient.get(`/projects/?${queryString}`)
      return response.data
    }
  }
})
