import { encodeQueryData } from './utils'

export default (httpClient) => ({
  project: {
    /**
     * List Ceta projects
     * @param {search} Partial or full project name, storagelocation
     * @returns {Object} List of CETA project matching the provided filters
     */
    async list(search) {
      const queryString = encodeQueryData({
        has_storage: true,
        search
      })
      const response = await httpClient.get(`/ceta/project/?${queryString}`)
      return response.data
    }
  }
})
