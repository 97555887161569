import { encodeQueryData } from './utils'

export default (httpClient) => ({
  wips: {
    async list({ site, search, limit, offset }) {
      const queryString = encodeQueryData({
        site,
        search,
        limit,
        offset
      })

      const response = await httpClient.get(`/wip-apis/wip/?${queryString}`)
      return response.data
    },
    async retrieve(id) {
      const response = await httpClient.get(`/wip-apis/wip/${id}/`)
      return response.data
    }
  },

  masters: {
    async list({ site, search, limit, offset }) {
      const queryString = encodeQueryData({
        site,
        search,
        limit,
        offset
      })
      const response = await httpClient.get(
        `/apis/v2/master-tapes/?${queryString}`
      )
      return response.data
    },
    async retrieve(id) {
      const response = await httpClient.get(`/apis/v2/master-tapes/${id}/`)
      return response.data
    }
  },

  masterIterations: {
    async create(iterationId, payload) {
      const response = await httpClient.post(
        `/apis/master-tapes-iteration/${iterationId}/`,
        payload
      )
      return response.data
    }
  },
  masterIterationStatuses: {
    async create(iterationStatusId, formData) {
      const response = await httpClient.post(
        `/apis/master-tapes-iteration-status/${iterationStatusId}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      return response.data
    }
  },
  sites: {
    async list() {
      const response = await httpClient.get(`/apis/site/`)
      return response.data
    }
  }
})
